<template>
    <div id=maincontent>
        <div id="transcriptioncontent">
            <div class="content" :style="filterSectionHeight">
                <div id="transcriptionname" class="main-title"></div>
                <div id="transcription" class="speech"></div>
            </div>
        </div>
        <div id="mediacontent">
            <div id="audiocontrol">
            </div>
        </div>
    </div>
</template>

<script>

  export default {
    name: 'EditorJs',
    data () {
      return {
        modelValue: {},
        timeUpdating: false,
        contents: {},
        lastPositions: undefined,
        transcription: undefined,
        htmlData: [],
        viewOnly: true,
        windowHeight: window.innerHeight
      };
    },
    mounted () {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      });
    },
    methods: {
      onResize () {
        this.windowHeight = window.innerHeight;
      },
      async updateContent (transcription) {
        this.transcription = transcription;
        await this.loadData();
      },
      async loadData () {
        const syncData = [];
        const speakerColors = this.setupColors(this.transcription);
        const sortedParts = this.order(this.transcription);
        sortedParts.forEach((part) => {
          this.generatePartHTML(part, speakerColors, syncData);
        });

        this.htmlData = syncData;
      },
      htmlTranscription () {
        return document.getElementById('transcription').cloneNode(true);
      },
      generatePartHTML (part, speakerColors, syncData) {
        let prevSpeaker = '';
        let prevSpeakerElement = null;
        if (part.type === 'Text') {
          const div = document.createElement('div');
          if (!this.viewOnly) {
            div.setAttribute('contenteditable', 'true');
          }
          div.setAttribute('class', 'speech-message');
          if (part.align === 0) {
            div.setAttribute('align', 'justify');
          } else if (part.align === 1) {
            div.setAttribute('align', 'left');
          } else if (part.align === 2) {
            div.setAttribute('align', 'right');
          } else if (part.align === 3) {
            div.setAttribute('align', 'center');
          }
          div.style.borderColor = speakerColors[prevSpeaker];
          const channel = part.channel;

          if (part.words && part.words.length > 0) {
            part.words.forEach((word, index, array) => {
              const span = document.createElement('span');
              span.setAttribute('class', 'span');

              // Tornar primeira letra maiúscula e adicionar espaços e pontuação à última palavra.
              // Essa implementação leva em conta só as palavras de cada parte, não a propriedade displaytext do banco.
              if (index === 0) {
                span.innerText = word.word.charAt(0).toUpperCase() + word.word.slice(1) + ' ';
              } else if (index === array.length - 1) {
                span.innerText = word.word + '.';
              } else {
                span.innerText = word.word + ' ';
              }
              span.addEventListener('click', (e) => {
                // document.getElementById('playerElement').currentTime = word.begin + 0.00001;
                // video.currentTime = word.begin + 0.00001; TODO: Check video
                this.$emit('positionTime', parseFloat(word.begin + 0.00001));
              });

              div.appendChild(span);
              syncData.push({
                span: span,
                begin: word.begin,
                end: word.end,
                speakerelement: prevSpeakerElement,
                div: div,
                channel: channel
              });
            });
            document.getElementById('transcription').appendChild(div);
          } else {
            const span = document.createElement('span');
            span.setAttribute('class', 'span');
            div.appendChild(span);
            document.getElementById('transcription').appendChild(div);
          }
        } else if (part.type === 'Speaker') {
          const h3 = document.createElement('h3');
          h3.setAttribute('class', 'speech-autor');
          h3.innerText = part.displaytext;
          document.getElementById('transcription').appendChild(h3);
          prevSpeaker = part.speaker;
          prevSpeakerElement = h3;
        } else if (part.type === 'FreeText') {
          const p = document.createElement('p');
          p.setAttribute('class', 'speech');

          let pHTML = '';

          if (part.displayFormat) {
            part.displayFormat.textFormats.forEach(function (format) {
              if (format === 0) {
                pHTML += part.displaytext.substring(format.start, format.start + format.length);
                return;
              }

              let tagStart = '';
              let tagEnd = '';

              const F_BOLD = 1 << 0;
              const F_ITALIC = 1 << 1;
              const F_UNDERLINE = 1 << 2;

              if (format.format & F_BOLD) {
                tagStart += '<b>';
                tagEnd += '</b>';
              }

              if (format.format & F_ITALIC) {
                tagStart += '<i>';
                tagEnd += '</i>';
              }

              if (format.format & F_UNDERLINE) {
                tagStart += '<u>';
                tagEnd += '</u>';
              }

              pHTML += tagStart + part.displaytext.substring(format.start, format.start + format.length) + tagEnd;
            });
          }

          p.innerHTML = pHTML;
          document.getElementById('transcription').appendChild(p);
        }
      },
      setupColors (jsonData) {
        // Configurar cor de cada Speaker na transcrição
        const colors = ['#007AFF', '#FF3B30', '#34AADC', '#FF9500', '#5AC8FA', '#FFCC00', '#4CD964', '#5856DC'];
        const speakerColors = [];
        if (jsonData.metadata && jsonData.metadata.channels) {
          jsonData.metadata.channels.forEach(function (element, index, array) {
            speakerColors[element.name.toLowerCase()] = colors[index];
          });
        }
        return speakerColors;
      },
      order (jsonData) {
        const isFirefox = typeof InstallTrigger !== 'undefined';
        // Ordenar partes por displayPosition
        const sortedParts = jsonData.parts.sort(function (a, b) {
          if (isFirefox) {
            return a.displayPosition > b.displayPosition ? 1 : -1;
          } else {
            return a.begin >= b.begin ? 1 : -1;
          }
        });
        return sortedParts;
      },
      updateHighlightWord (currentTime) {
        // const transcriptionDiv = document.getElementById('transcription');
        this.htmlData.forEach(function (element, index, array) {
          if (currentTime + 0.25 >= element.begin && currentTime + 0.25 < element.end) {
            element.span.style.background = '#EEB4B4';
            // element.span.setAttribute("class", "highlight");
          } else {
            element.span.style.background = '#ffffff'; // TODO: Check for previous styles
            // element.span.setAttribute("class", "span");
          }
        });
      }
    },
    computed: {
      filterSectionHeight () {
        return {
          'max-height': `${this.windowHeight - 310}px`,
          'min-height': `${this.windowHeight - 310}px`
        };
      }
    }
  };
</script>

<style scoped>
    .content {
        overflow-y: scroll;
        margin: 0rem;
        padding: 0.5rem;
        border: 2px dashed #0D0D0D20;
        border-radius: 0.5rem;
        pre {
            border-radius: 5px;
            color: #333;
        }
    }
</style>
