<template>
  <section>
    <MenuShortcut  v-if="!editorJS" ref="menuShortcut" :transcriptionName="transcriptionName" :transcriptionId="transcriptionId" @close="onCloseEditor()" @newTranscription="newTranscription()" @openTranscription="openTranscription()" @importTranscription="importTranscription()" @save="onSaveShortCut()"
      @export="onExport($event)" @print="print()" @undo="undo()" @redo="redo()" @copy="copy()" @cut="cut()" @paste="paste()" @expotTo="expotTo()"
      @search="search()" @replace="replace()" @insertLocationCursor="insertLocationCursor()" @insertTextCursor="insertTextCursor()"
      @showPartNumberCheckBox="showPartNumberCheckBox()" @showPartPagesCheckBox="showPartPagesCheckBox()" @properties="properties()"
      @preferences="preferences()" @forward="onForwardClicked()" @backward="onBackwardClicked()" @bold="bold()" @fontFamily="fontFamily(fontProperties)" @italic="italic()" />
    <Menu ref="menu" class="section-menu" :transcriptionName="transcriptionName" :transcriptionId="transcriptionId" :quality="quality"
          @showVideo="onVideoShow($event)" @close="onCloseEditor()" @play="onPlayClicked()" @pause="onPauseClicked()" @stop="onStopClicked()" @forward="onForwardClicked()"
          @backward="onBackwardClicked()" @bold="bold()" @fontFamily="fontFamily(fontProperties)" @italic="italic()" @strike="strike()" @highlight="highlight()"
          @bulletList="bulletList()" @orderedList="orderedList()" @undo="undo()" @redo="redo()" @save="onSave()"
          @export="onExport($event)" @onVolumeChanged="onVolumeChanged($event)"/>
    <b-card ref="editContainer" no-body class="edit-container" :style="{ 'max-height': `${filterSectionHeight}px`, 'margin-right': `${filterVideoWidth}px`}">
        <div ref="editContainer2" class="transcription-container">
          <EditorJs ref="editor" @positionTime="onClickPosition" v-if="editorJS"/>
        </div>
    </b-card>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :manual-pagination="true"
      :preview-modal="true"
      :pdfContentWidth='400'
      :paginate-elements-by-height="1400"
      pdf-content-width="200%"
      :filename=this.transcriptionName
      ref="html2Pdf"
      :htmlToPdfOptions=this.pdfOptions
    >
      <section slot="pdf-content" ref="pdfContent">
      </section>
    </vue-html2pdf>
        <!-- <div class="vld-parent">
          <loading :active.sync="isLoading"
            :can-cancel="false"
            :is-full-page="fullPage"></loading>
        </div> -->
      <div class="wave-tag" :style="{'right': `${filterVideoWidth > 0 ? '320' : '0'}px`, 'left': `${filterVideoWidth > 0 ? '200' : '200'}px`}">
        <Wave ref="wave" @timeupdate="onAudioTimeUpdate" @showVideoButton="showVideoButton($event)" @pipVideo="pipVideo($event)" :mediaPath="mediaPath" class="wave" :style="{ 'min-width': `${filterWaveWidth}px`}"/>
      </div>
  </section>
</template>

<script>
  // import Loading from 'vue-loading-overlay';
  import EditorJs from './Editor/EditorJs';
  import Menu from './Ribbon/Menu.vue';
  import Wave from './Wave/Wave.vue';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import DocumentCreator from '../helpers/docx-generator';
  import VueHtml2pdf from 'vue-html2pdf';
  import MenuShortcut from './Ribbon/MenuShortcut/MenuShortcut.vue';
  export default {
    components: {
      EditorJs,
      Menu,
      Wave,
      VueHtml2pdf,
      MenuShortcut
      // Loading
    },
    data () {
      return {
        transcription: {},
        transcriptionName: 'Transcrição',
        pdfOptions: {
          margin: [10, 10],
          filename: this.transcriptionName,
          image: {
            type: 'jpeg', quality: 0.98
          },
          html2canvas: {
            scale: 2, letterRendering: true
          },
          jsPDF: {
            unit: 'pt',
            format: 'letter',
            orientation: 'portrait'
          },
          pagebreak: {
            mode: ['avoid-all', 'css', 'legacy']
          }
        },
        transcriptionId: '',
        quality: {
          status: '',
          count: {
            good: 0,
            medium: 0,
            poor: 0
          }
        },
        blocks: [],
        mediaPath: '',
        editorJS: true,
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        filterVideoWidth: 0
      };
    },
    created () {
      if (this.$route.params.transcriptionInfo) {
        this.transcription = this.$route.params.transcriptionInfo.transcription;
        this.transcriptionName = (this.$route.params.transcription.name.length > 80) ? this.$route.params.transcription.name.substr(0, 80) + '...' : this.$route.params.transcription.name;
        this.transcriptionId = this.$route.params.transcription.id;
        this.quality.status = this.transcription.quality;
        this.quality.count = this.transcription.qualityCounts;
        this.mediaPath = this.$route.params.path;
      } else {
        console.log('erro trying to load transcription');
      }
    },
    async beforeDestroy () {
      this.$refs.wave.closePIP();
      this.isVideoPlaying = false;
      clearInterval(this.seekbarInterval);
    },
    async mounted () {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      });
      try {
        if (this.editorJS) {
          await this.$refs.editor.updateContent(this.transcription);
        } else {
          const value = await this.formatTranscription();
          await this.$refs.editor.updateContent(value);
        }
      } catch (reason) {
        console.log(`initialization failed because of ${reason}`);
      }
    },
    methods: {
      onResize () {
        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth;
      },
      onSave () {

      },
      onVolumeChanged (value) {
        this.$refs.wave.updateVolume(value);
      },
      onCloseEditor () {
        this.$router.push({
          path: 'transcriptions'
        });
      },
      async onExport (value) {
        if (value === 'word') {
          this.exportToDoc();
        } else if (value === 'pdf') {
          this.exportToPDF();
        }
      },
      async exportToDoc () {
        const documentCreator = new DocumentCreator();
        const docx = await documentCreator.createDocx(this.$refs.editor.htmlTranscription());
        await documentCreator.generateDocx(docx, this.transcriptionName);
      },
      async exportToPDF () {
        this.$refs.pdfContent.replaceChildren();
        this.$refs.pdfContent.appendChild(this.$refs.editor.htmlTranscription());
        this.$refs.html2Pdf.generatePdf();
      },
      bold () {
        this.$refs.editor.bold();
      },
      fontFamily (fontProperties) {
        this.$refs.editor.updateFontProperties(fontProperties);
      },
      italic () {
        this.$refs.editor.italic();
      },
      strike () {
        this.$refs.editor.strike();
      },
      highlight () {
        this.$refs.editor.highlight();
      },
      bulletList () {
        this.$refs.editor.bulletList();
      },
      orderedList () {
        this.$refs.editor.orderedList();
      },
      undo () {
        this.$refs.editor.undo();
      },
      redo () {
        this.$refs.editor.redo();
      },
      onClickPosition (pos) {
        this.$refs.wave.setPosition(pos);
      },
      showVideoButton (value) {
        this.$refs.menu.showVideoButton(value);
      },
      pipVideo (value) {
        if (value) {
          this.filterVideoWidth = 0;
        } else {
          this.filterVideoWidth = 320;
        }
        this.$refs.menu.pipVideo(value);
      },
      onAudioTimeUpdate (currentTime) {
        this.$refs.editor.updateHighlightWord(currentTime);
      },
      async onVideoShow (value) {
        if (value) {
          this.filterVideoWidth = 320;
        } else {
          this.filterVideoWidth = 0;
        }
        this.$refs.wave.onShowVideo();
      },
      onPlayClicked () {
        this.$refs.wave.onPlay();
      },
      onPauseClicked () {
        this.$refs.wave.onPause();
      },
      onStopClicked () {
        this.$refs.wave.onStop();
      },
      onForwardClicked () {
        this.$refs.wave.onForward();
      },
      onBackwardClicked () {
        this.$refs.wave.onBackward();
      },
      exitTranscription () {

      },
      newTranscription () {
        this.$refs.editor.newTranscription();
      },
      openTranscription () {
      },
      importTranscription () {
      },
      onSaveShortCut () {
      },
      onExportShortCut () {
        this.$refs.editor.onExportShortcut();
      },
      print () {
      },
      async formatHtmlTranscription () {
        const divContent = document.createElement('div');
        this.transcription.parts.forEach(part => {
          if (part.type === 'Text') {
            const div = document.createElement('div');
            div.setAttribute('class', 'speech-message');
            if (part.align === 0) {
              div.setAttribute('align', 'justify');
            } else if (part.align === 1) {
              div.setAttribute('align', 'left');
            } else if (part.align === 2) {
              div.setAttribute('align', 'right');
            } else if (part.align === 3) {
              div.setAttribute('align', 'center');
            }
            // div.style.borderColor = speakerColors[prevSpeaker];

            if (part.words && part.words.length > 0) {
              part.words.forEach(function (word, index, array) {
                var span = document.createElement('span');
                span.setAttribute('class', 'span');

                // Tornar primeira letra maiúscula e adicionar espaços e pontuação à última palavra.
                // Essa implementação leva em conta só as palavras de cada parte, não a propriedade displaytext do banco.
                if (index === 0) {
                  span.innerText = word.word.charAt(0).toUpperCase() + word.word.slice(1) + ' ';
                } else if (index === array.length - 1) {
                  span.innerText = word.word + '.';
                } else {
                  span.innerText = word.word + ' ';
                }

                // span.addEventListener('click', function (e) {
                //   // mostAdvancedHighlightSpan = 0;
                //   document.getElementById('playerElement').currentTime = word.begin + 0.00001;
                //   // audio.currentTime = word.begin + 0.00001;
                // });

                // span.addEventListener('DOMSubtreeModified', function (e) {
                //   console.log(e);
                // });

                div.appendChild(span);
                divContent.appendChild(div);
              });
            } else {
              var span = document.createElement('span');
              span.setAttribute('class', 'span');
              div.appendChild(span);
              divContent.appendChild(div);
            }
          }
        });
        return divContent;
      },
      async formatTranscription () {
        const value = {
          type: 'doc',
          content: []
        };
        this.transcription.parts.forEach(element => {
          if (element.type === 'FreeText') {
            // value.content.push('');
          } else if (element.type === 'Text' && element._id) {
            const content = [];
            content.push({
              type: 'text',
              text: element.displaytext
            });
            value.content.push({
              type: 'CustomParagraph',
              content,
              attrs: {
                index: element.pos,
                words: element.words,
                id: element._id
              }
            });
          } else if (element.type === 'Speaker' && element._id) {

          }
        });
        return value;
      }
    },
    computed: {
      filterSectionHeight () {
        return this.windowHeight - 275;
      },
      filterWaveWidth () {
        return 350;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .section-menu {
    overflow-x: hidden;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    background-color: #ddd9d9;
  }

  .section-title h2 {
    margin-left: 5px;
  }

  .edit-container {
    display: flex;
    height: 80%;
    padding-right: 20px;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
  }

  .transcription-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
  }

  .wave-tag {
    position: fixed;
    bottom: 0;
    box-shadow: 0px 0px 3px 0px #0e1e3b inset !important;
    background-color: #F5F8FA;
    text-align:center;
  }

</style>
