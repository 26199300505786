<template>
<div class="main-container">
   <div class="file-container">
        <b-nav-item-dropdown size="lg" split offset="25" text="Arquivo" no-caret>
          <div>
          <b-dropdown-item
          @click="newTranscription()"
          id="new_transcription">
          <p class="dropdown-item fontes">Nova Transcrição
            <span style="margin-left: 52px;">Ctrl + N</span>
            </p></b-dropdown-item>
          <b-dropdown-item href="#" @click="openTranscription()"><p class="dropdown-item">Abrir transcrição
            <span style="margin-left: 54px;">Ctrl + Alt + O</span>
            </p></b-dropdown-item>
          <b-dropdown-item href="#" @click="importTranscription()"><p class="dropdown-item">Importar transcrição</p></b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item href="#" @click="onSave()"><p class="dropdown-item">Salvar
            <span style="margin-left: 127px;">Ctrl + S</span>
            </p></b-dropdown-item>
          <b-dropdown-item ref="showModal" @click="showModalExport(), exportTranscription()"><p class="dropdown-item">Exportar
            <span style="margin-left: 113px;">Ctrl + E</span>
             <ExportTranscription ref="modalExport" @export="exportTo($event)" />
            </p></b-dropdown-item>
          <b-dropdown-divider class="mb-0 pt-0"></b-dropdown-divider>
          <b-dropdown-item href="#" @click="print()"><p class="dropdown-item mb-2">Imprimir
            <span style="margin-left: 112px;">Ctrl + P</span>
            </p></b-dropdown-item>
          </div>
        </b-nav-item-dropdown>
   </div>
</div>
</template>
<script>
  export default {
    data () {
      return {};
    },
    methods: {
      newTranscription () {
        this.$emit('newTranscription');
      },
      openTranscription () {
      },
      importTranscription () {
      },
      onSave () {
      },
      showModalExport () {
        this.$refs.modalExport.showModal();
      },
      onPdfExport () {
        this.$emit('export', 'pdf');
      },
      onWordExport () {
        this.$emit('export', 'word');
      },
      exportTranscription () {
      },
      exportTo (exportTo) {
        if (exportTo === 'pdf') {
          this.onPdfExport();
        } else if (exportTo === 'word') {
          this.onWordExport();
        }
      },
      print () {
      }
    }
  };
</script>
<style lang="scss" scoped>
.main-container {
    display: flex;
    list-style: none;
}
.file-container h6 {
    color: #153252;
    font-family: Poppins;
    font-weight: 600;
    margin-left: 23px;
    margin-top: 5px;
}

.dropdown-item {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: #6278A3;
    height: 18px;
    padding: 0 0 0 0 !important;
    margin-top: 0;
    margin-bottom: 0;
}

</style>
