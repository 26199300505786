<template>
  <div>
    <b-card no-body :style="{ 'margin-right': `${filterVideoWidth}px` }">
      <div class="container-name">
        <b-card-text class="transcription-name" v-text="transcriptionName"></b-card-text>
        <b-button squared id="close" @click="onCloseEditor" class="btn-close">
          <img src="@/assets/icons/close.svg"/>
        </b-button>
      </div>
    </b-card>
    <b-card no-body :style="{ 'margin-right': `${filterVideoWidth}px` }">
      <b-row>
        <File @save="onSave()" @export="onExport($event)"/>
        <div class="line"></div>
        <Player ref="player" @onVolumeChanged="onVolumeChanged($event)" @showVideo="onShowVideo($event)" @play="onPlayClicked()" @pause="onPauseClicked()" @stop="onStopClicked()" @forward="onForwardClick()" @backward="onBackwardClick()"/>
        <div class="line"></div>
        <Format @bold="bold()" @fontFamily="fontFamily(fontProperties)" @italic="italic()" @strike="strike()" @highlight="highlight()"
        @bulletList="bulletList()" @orderedList="orderedList()" @undo="undo()" @redo="redo()"
        v-if="showFormat"/>
        <div class="line" v-if="showFormat"></div>
        <Feedback :transcriptionId="transcriptionId" :quality="quality"/>
      </b-row>
    </b-card>
</div>
</template>

<script>
  import Player from './Player/Player.vue';
  import File from './File/File.vue';
  import Format from './Tools/Format.vue';
  import Feedback from './Feedback/Feedback.vue';

  export default {
    components: {
      Player,
      File,
      Format,
      Feedback
    },
    props: { transcriptionName: { type: String }, transcriptionId: { type: String }, quality: { type: Object } },
    data () {
      return {
        showFormat: false,
        filterVideoWidth: 0
      };
    },
    methods: {
      onSave () {
        this.$emit('save');
      },
      onExport (value) {
        this.$emit('export', value);
      },
      onVolumeChanged (value) {
        this.$emit('onVolumeChanged', value);
      },
      showVideoButton (value) {
        this.$refs.player.showVideoButton(value);
      },
      onCloseEditor () {
        this.$emit('close');
      },
      pipVideo (value) {
        if (value) {
          this.filterVideoWidth = 0;
        } else {
          this.filterVideoWidth = 320;
        }
        this.$refs.player.pipVideo(!value);
      },
      onShowVideo (value) {
        if (value) {
          this.filterVideoWidth = 320;
        } else {
          this.filterVideoWidth = 0;
        }
        this.$emit('showVideo', value);
      },
      onPlayClicked () {
        this.$emit('play');
      },
      onPauseClicked () {
        this.$emit('pause');
      },
      onStopClicked () {
        this.$emit('stop');
      },
      onForwardClick () {
        this.$emit('forward');
      },
      onBackwardClick () {
        this.$emit('backward');
      },
      bold () {
        this.$emit('bold');
      },
      fontFamily (fontProperties) {
        this.$emit('fontFamily', fontProperties);
      },
      italic () {
        this.$emit('italic');
      },
      strike () {
        this.$emit('strike');
      },
      highlight () {
        this.$emit('highlight');
      },
      bulletList () {
        this.$emit('bulletList');
      },
      orderedList () {
        this.$emit('orderedList');
      },
      undo () {
        this.$emit('undo');
      },
      redo () {
        this.$emit('redo');
      }
    }
  };
</script>

<style lang="scss" scoped>

  .container-name {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-content: space-between;
      height: 40px;
      background: #F5F8FA;
      border-radius: 4px;
  }

  .btn-close {
      border: none;
      background: none;
      margin-right: 20px;
      &:focus {
          box-shadow: none;
      }

      & > img {
          width: 15px;
          height: 15px;
      }
  }

  .transcription-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 550;
    font-size: 18px;
    margin-top: 10px;
    margin-left: 20px;
    line-height: 17px;
    color: rgba(98, 120, 163, 1);

  }
.line {
  &::after {
    content: "";
    position: absolute;
    height: 30px;
    margin-top: 3px;
    margin-left: 10px;
    width: 1.5px;
    background-color: #bdbdbd;
  }
}
.row {
  height: 43px;
  margin-top: 5px;
}
</style>
