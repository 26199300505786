<template>
    <div id="file-container">
      <b-button hidden squared id="file-save" @click="saveTranscription"
        style="background: none; border: none; margin-left: 20px;" class="btn-video-controls">
          <b-tooltip target="file-save" placement="bottom" triggers="hover">Salvar</b-tooltip>
            <img src="@/assets/icons/save_black_24dp.svg" height="24px" width="24px"/>
        </b-button>
        <b-button squared id="file-export" @click="exportTranscription()"
          style="background: none; border: none; margin-left: 20px;">
            <!-- <b-tooltip target="file-export" placement="bottom" triggers="hover">Exportar</b-tooltip> -->
          <b-button @click="showModalExport()" squared style="background: none; border: none; margin-top: -5px; margin-left: 20px" class="btn-video-controls">
              <img src="@/assets/icons/import_export_black_24dp.svg" height="24px" width="24px"/>
          </b-button>
        </b-button>
          <ExportTranscription ref="modalExport" @export="exportTo($event)" />
      </div>
</template>

<script>
  import ExportTranscription from './ExportTranscription.vue';
  export default {
    components: { ExportTranscription },
    data () {
      return {};
    },
    methods: {
      showModalExport () {
        this.$refs.modalExport.showModal();
      },
      onPdfExport () {
        this.$emit('export', 'pdf');
      },
      onWordExport () {
        this.$emit('export', 'word');
      },
      exportTranscription () {
      },
      exportTo (exportTo) {
        if (exportTo === 'pdf') {
          this.onPdfExport();
        } else if (exportTo === 'word') {
          this.onWordExport();
        }
      },
      saveTranscription () {
        this.$emit('save');
      }

    }
  };
</script>

<style lang="scss" scoped>
  .player-controls {
    margin-left: 80px;
    margin-top: 0px;

    .video-controls {
      .btn-video-controls {
        padding: 0;
        border: none;
        background: none;
        margin-right: 15px;
        margin-top: 3px;
        margin-bottom: 3px;

        // &:focus {
        //   box-shadow: none;
        // }

        &:hover {
            background-color: #efeff38f !important;
            transition: #0000 0.2s linear !important;
        }

        & > img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
</style>
