<template>
<div>
  <div class="edit-container">
    <div class="file-container">
  <b-nav-item-dropdown id="dropdown-form" offset="25" text="Editar" class="nav-dropdown" no-caret>
      <b-dropdown-form>
        <b-dropdown-item></b-dropdown-item>
      <b-dropdown-item id="dropdown-form" href="#" @click="undo()">
        <p class="dropdown-item">Desfazer
        <span style="margin-left: 220px;">Ctrl + Z</span></p>
      </b-dropdown-item>
      <b-dropdown-item href="#" @click="redo()"><p class="dropdown-item">Refazer
       <span style="margin-left: 229px;">Ctrl + Shift + Z</span>
       </p></b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item href="#" @click="copy()">
        <p class="dropdown-item">Copiar<span style="margin-left: 237px;">Ctrl + C</span></p>
      </b-dropdown-item>
      <b-dropdown-item href="#" @click="cut()"><p class="dropdown-item">Cortar
        <span style="margin-left: 236px;">Ctrl + X</span></p>
      </b-dropdown-item>
      <b-dropdown-item href="#" @click="paste()"><p class="dropdown-item">Colar
        <span style="margin-left: 243px;">Ctrl + V</span></p>
      </b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item href="#" @click="exportTo()"><p class="dropdown-item">
          Exportar para área de transferência<span style="margin-left: 34px;">Ctrl + Shift + C</span></p>
      </b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item href="#" @click="search()"><p class="dropdown-item">Pesquisar
          <span style="margin-left: 212px;">Ctrl + F</span></p>
      </b-dropdown-item>
      <b-dropdown-item href="#" @click="replace()"><p class="dropdown-item">Substituir
        <span style="margin-left: 215px;">Ctrl + R</span></p>
      </b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item href="#" @click="insertLocationCursor()"><p class="dropdown-item">Inserir um local no cursor
        <span style="margin-left: 10px;">Ctrl + Enter</span></p>
        </b-dropdown-item>
      <b-dropdown-item href="#" @click="insertTextCursor()"><p class="dropdown-item">Inserir um texto livre no cursor
        <span style="margin-left: 71px;">Shift + Enter</span>
        </p></b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
       <b-form-checkbox
        style="font-family: Poppins; font-size: 14px; font-weight: 400; color: #6278A3; line-height: 25px;
        margin-top: -4px; margin-left:25px">Mostrar número das partes<span style="margin-left:69px;">Ctrl + L</span>
      </b-form-checkbox>
        <b-form-checkbox style="margin-left:25px;"><p class="dropdown-item">Mostrar número de páginas</p></b-form-checkbox>
        <b-dropdown-item href="#" @click="properties()"><p class="dropdown-item">Propriedades</p></b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item href="#" @click="preferences()"><p class="dropdown-item mb-2">Preferências...</p></b-dropdown-item>
      </b-dropdown-form>
    </b-nav-item-dropdown>
  </div>
 </div>
</div>
</template>

<script>
  export default {
    data () {
      return {};
    },
    methods: {
      undo () {
      },
      redo () {
      },
      copy () {
      },
      cut () {
      },
      paste () {
      },
      exportTo () {
      },
      search () {
      },
      replace () {
      },
      insertLocationCursor () {
      },
      insertTextCursor () {
      },
      showNumberPart (checked) {
      },
      showNumberPages (checked) {
      },
      properties () {
      },
      preferences () {
      }
    }
  };
</script>

<style lang="scss" scoped>
.file-container h6 {
    color: #153252;
    font-family: Poppins;
    font-weight: 600;
    margin-left: 23px;
    margin-top: 5px;
}
.dropdown-item {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: #6278A3;
    height: 18px;
    padding: 0 0 0 0 !important;
    margin-top: 0;
    margin-bottom: 0;
}
.b-dropdown-form {
  padding: 0px !important;
}
</style>
