import { render, staticRenderFns } from "./Player.vue?vue&type=template&id=312fe24a&scoped=true"
import script from "./Player.vue?vue&type=script&lang=js"
export * from "./Player.vue?vue&type=script&lang=js"
import style0 from "./Player.vue?vue&type=style&index=0&id=312fe24a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "312fe24a",
  null
  
)

export default component.exports