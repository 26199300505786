<template>
  <div>
  <div class="player-controls">
    <div class="video-controls">
      <b-row>
      <button @click="undo()"
          squared
          id="undo"
          style="background: none; border: none"
          class="btn-video-controls">
          <b-tooltip target="undo" placement="bottom" triggers="hover"
              >Desfazer</b-tooltip
            >
          <img src="@/assets/icons/format/redo.svg" height="28px" width="28px"/>
      </button>
      <button @click="redo()"
          squared
          id="redo"
          style="background: none; border: none; margin-rigth: 20px;"
          class="btn-video-controls">
          <b-tooltip target="redo" placement="bottom" triggers="hover"
              >Refazer</b-tooltip
            >
          <img src="@/assets/icons/format/undo.svg" height="28px" width="28px"/>
      </button>
      <button @click="toggleBold()" :class="{ 'is-active': true }"
          squared
          id="bold"
          style="background: none; border: none; margin-left: 30px;"
          class="btn-video-controls">
          <b-tooltip target="bold" placement="bottom" triggers="hover"
              >Negrito</b-tooltip
            >
        <img src="@/assets/icons/format/bold.svg" />
      </button>
      <button @click="toggleFontFamily()"
                squared
                id="font_family"
                style="background: none; border: none; margin-right: 5px; margin-left: -10px"
                class="btn-video-controls"
                >
            <img src="@/assets/icons/format/font_family.svg" height="28px" width="28px"/>
      </button>
      <SelectFonts ref="selectedFonts" @fontFamily="fontFamily(fontProperties)" />
      <button @click="toggleItalic()" :class="{ 'is-active': true }"
          squared
          id="italic"
          style="background: none; border: none"
          class="btn-video-controls">
          <b-tooltip target="italic" placement="bottom" triggers="hover"
              >Itálico</b-tooltip
            >
          <img src="@/assets/icons/format/italic.svg" height="28px" width="28px"/>
      </button>
      <button @click="toggleStrike()" :class="{ 'is-active': true }"
          squared
          id="strike"
          style="background: none; border: none; margin-left: -10px;"
          class="btn-video-controls">
          <b-tooltip target="strike" placement="bottom" triggers="hover"
              >Tachado</b-tooltip
            >
          <img src="@/assets/icons/format/strike.svg" height="28px" width="28px"/>
      </button>
      <button @click="OnHighlight()" :class="{ 'is-active': true }"
          squared
          id="highlight"
          style="background: none; border: none; margin-left: -8px;"
          class="btn-video-controls">
          <b-tooltip target="highlight" placement="bottom" triggers="hover"
              >Realçar</b-tooltip
            >
          <img src="@/assets/icons/format/highlight.svg" height="28px" width="28px"/>
      </button>
      <button @click="toggleBulletList()" :class="{ 'is-active': true }"
          squared
          id="bulletList"
          style="background: none; border: none"
          class="btn-video-controls">
          <b-tooltip target="bulletList" placement="bottom" triggers="hover"
              >Marcadores</b-tooltip
            >
          <img src="@/assets/icons/format/orderedList.svg" height="28px" width="28px"/>
      </button>
      <button @click="toggleOrderedList()" :class="{ 'is-active': true }"
          squared
          id="orderedList"
          style="background: none; border: none"
          class="btn-video-controls">
          <b-tooltip target="orderedList" placement="bottom" triggers="hover"
              >Ordenação</b-tooltip
            >
          <img src="@/assets/icons/format/bullet_list.svg" height="28px" width="28px"/>
      </button>
      </b-row>
    </div>
  </div>
  </div>
</template>

<script>
  import SelectFonts from './Fonts/SelectFonts.vue';
  export default {
    components: { SelectFonts },
    data () {
      return {
        selectedFontSize: {
        },
        selectedFontFamily: [],
        arr: [
          'Arial Format',
          'Arial Black'
        ]
      };
    },
    methods: {
      toggleBold () {
        this.$emit('bold');
      },
      toggleFontFamily () {
        this.$refs.selectedFonts.showModal();
      },
      fontFamily (selectedFont, fontSize) {
        console.log(selectedFont, fontSize);
      },
      toggleItalic () {
        this.$emit('italic');
      },
      toggleStrike () {
        this.$emit('strike');
      },
      OnHighlight () {
        this.$emit('highlight');
      },
      toggleBulletList () {
        this.$emit('bulletList');
      },
      toggleOrderedList () {
        this.$emit('orderedList');
      },
      undo () {
        this.$emit('undo');
      },
      redo () {
        this.$emit('redo');
      }
    }
  };
</script>

<style lang="scss">
  .player-controls {
    margin-left: 80px;
    margin-top: 0px;

    .video-controls {
      .btn-video-controls {
        padding: 0;
        border: none;
        background: none;
        margin-right: 15px;
        margin-top: 3px;
        margin-bottom: 3px;

        // &:focus {
        //   box-shadow: none;
        // }

        &:hover {
            box-shadow: 0 0 11px rgba(33,33,33,.2);
            // background-color: #efeff38f !important;
            // transition: #0000 0.2s linear !important;
        }

        & > img {
          width: 30px;
          height: 30px;
        }
      }
    }

  }
  .btn-transparent {
    font-family: Poppins;
    background-color: #e5e5e5;
    color: #6278A3 !important;
    font-weight: 500;
    width: 124px;
  }
  .btn-primary {
    font-family: Poppins;
    font-weight: 500;
    width: 124px;
  }

  .group {
    color: #153252;
    border: none;
  }

  .list-field {
    color: #6278A3;
  }

</style>
