<template>
  <div>
    <div class="player-controls">
      <div class="video-controls">
          <b-button
            squared
            v-if="showVideoBtn"
            id="video-show"
            @click="showVideo"
            style="background: none; border: none;"
            class="btn-video-controls"
          >
            <b-tooltip target="video-show" placement="bottom" triggers="hover"
              >Mostrar</b-tooltip
            >
            <img src="@/assets/icons/player/videocam_button.svg" height="20px" width="20px" v-if="isVideoMedia" />
          </b-button>
          <b-button
            squared
            id="play-pause"
            @click="playVideo"
            class="btn-video-controls"
          >
            <b-tooltip target="play-pause" placement="bottom" triggers="hover"
              >Iniciar</b-tooltip
            >
            <img src="@/assets/icons/player/play_button.svg" v-if="!isVideoPlaying" />
            <img src="@/assets/icons/player/pause.svg" height="20px" width="20px" v-if="isVideoPlaying" />
          </b-button>
          <b-button
            squared
            id="stop"
            @click="stopVideo"
            class="btn-video-controls"
          >
            <b-tooltip target="stop" placement="bottom" triggers="hover"
              >Parar</b-tooltip
            >
            <img src="@/assets/icons/player/stop_button.svg" height="18px" width="18px" />
          </b-button>
          <b-button
            squared
            id="backward"
            @click="backwardVideo"
            class="btn-video-controls"
          >
            <b-tooltip target="backward" placement="bottom" triggers="hover"
              >Voltar</b-tooltip
            >
            <img src="@/assets/icons/player/retrocede_button.svg" />
          </b-button>
          <b-button
            squared
            id="forward"
            @click="forwardVideo"
            class="btn-video-controls"
          >
            <b-tooltip target="forward" placement="bottom" triggers="hover"
              >Avançar</b-tooltip
            >
            <img src="@/assets/icons/player/fast-forward.svg" />
          </b-button>
          <b-button
                  squared
                  id="volume"
                  class="btn-video-controls"
          >
              <b-tooltip target="volume" placement="bottom" triggers="hover"
              >Volume</b-tooltip
              >
              <img src="@/assets/icons/player/volume_button.svg" />
          </b-button>
          <b-form-input
                  v-model="volumeValue"
                  type="range"
                  class="range-volume"
                  min="0"
                  max="100"
                  style="margin-right: 20px"
          ></b-form-input>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        name: '',
        srcVideo: '',
        videoType: '',
        currentVideoTime: 0,
        videoDuration: 0,
        isVideoPlaying: false,
        isVideoMedia: true,
        volumeValue: 30,
        showVideoStatus: false,
        showVideoBtn: false
      };
    },
    methods: {
      showVideo () {
        this.showVideoStatus = !this.showVideoStatus;
        this.$emit('showVideo', this.showVideoStatus);
      },
      showVideoButton (value) {
        this.showVideoBtn = value;
      },
      pipVideo (value) {
        this.showVideoStatus = value;
      },
      playVideo () {
        if (!this.isVideoPlaying) {
          this.$emit('play');
          this.isVideoPlaying = true;
        } else {
          this.$emit('pause');
          this.pauseVideo();
        }
      },
      pauseVideo () {
        this.isVideoPlaying = false;
      },
      stopVideo () {
        if (this.isVideoPlaying) {
          this.$emit('stop');
          this.isVideoPlaying = false;
        }
      },
      forwardVideo () {
        if (this.isVideoPlaying) {
          this.$emit('forward');
          // this.currentVideoTime = this.$refs.mediaVideo.currentTime + 5;
          // this.$refs.mediaVideo.currentTime = this.currentVideoTime;
        }
      },
      backwardVideo () {
        if (this.isVideoPlaying) {
          this.$emit('backward');
          // this.currentVideoTime = this.$refs.mediaVideo.currentTime - 5;
          // this.$refs.mediaVideo.currentTime = this.currentVideoTime;
        }
      },
      // onVideoTimeUpdate () {
      //   const currentTime = parseFloat(
      //     this.$refs.mediaVideo.currentTime.toFixed(2)
      //   );
      //   let blockIndex = 0;
      //   const length = this.content.transcription.parts.length;
      //   for (let index = 0; index < length; index++) {
      //     const part = this.content.transcription.parts[index];
      //     if (
      //       (part.begin === 0 || part.begin < currentTime) &&
      //       part.end > currentTime
      //     ) {
      //       blockIndex = index;
      //       break;
      //     }
      //   }
      //   this.$refs.editor.updateHighlightWord(currentTime, blockIndex);
      // },
      prettyTime (t) {
        let minutes = Math.floor(t / 60);
        if (minutes < 10) minutes = '0' + minutes;
        let seconds = Math.floor(t % 60);
        if (seconds < 10) seconds = '0' + seconds;
        return `${minutes}:${seconds}`;
      }
    },
    watch: {
      volumeValue () {
        const newValue = Math.abs(this.volumeValue / 100);
        this.$emit('onVolumeChanged', { volume: newValue });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .range-volume {
    max-width: 120px;
    padding-top: 14px;
  }
  .custom-range::-webkit-slider-thumb {
      background: gray;
  }

  .custom-range::-moz-range-thumb {
      background: #6278A3;
  }

  .custom-range::-ms-thumb {
      background: #6278A3;
  }
  .player-controls {
    margin-left: 35px;
    margin-top: 0px;

    .video-controls {
      .btn-video-controls {
        padding: 0;
        border: none;
        background: none;
        margin-right: 15px;
        margin-top: 3px;
        margin-bottom: 3px;

        &:focus {
          box-shadow: none;
        }

        & > img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

</style>
