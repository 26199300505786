<template>
<div>
    <b-card no-body :style="{ 'margin-right': `${filterVideoWidth}px` }">
      <div class="container-name">
        <b-card-text class="transcription-name" v-text="transcriptionName"></b-card-text>
        <b-button squared id="close" @click="onCloseEditor" class="btn-close">
          <img src="@/assets/icons/close.svg"/>
        </b-button>
      </div>
    </b-card>
    <b-card no-body :style="{ 'margin-right': `${filterVideoWidth}px` }">
      <b-row>
        <FileShortcut @newTranscription="newTranscription()" @openTranscription="openTranscription()"
          @importTranscription="importTranscription()" @save="onSave()" @export="onExport($event)"
          @print="print()"/>

        <EditShortcut @redo = "redo()" @copy="copy()" @cut="cut()" @paste="paste()"
          @exportTo="exportTo()" @search="search()" @replace="replace()"
          @insertLocationCursor="insertLocationCursor()" @insertTextCursor="insertTextCursor()"
          @showNumberPart="showNumberPart()" @showNumberPages="showNumberPages()"
          @properties="properties()" @preferences="preferences()"/>

        <ActionShortcut @forward="onForwardClick()" @backward="onBackwardClick()"
          @moveCursorToPlayback="moveCursorToPlayback()" @playPreviousWord="playPreviousWord()"
          @playCurrentWord="playCurrentWord()" @playNextWord="playNextWord()"/>

        <FormatShortcut @bold="bold()" @italic="italic()" @underline="underline()"
          @fontFamily="fontFamily(fontProperties)" @fontColor="fontColor()" @upercase="upercase()"
          @lowercase="lowercase()" @startLowercase="startLowercase()" @toggleBox="toggleBox()"
          @startUppercase="startUppercase()" @invertBox="invertBox()"/>
        <HelpShortcut @about="about()" @tutorial="tutorial()"/>
      </b-row>
    </b-card>
    </div>
</template>
<script>
  import ActionShortcut from './ActionShortcut/ActionShortcut.vue';
  import EditShortcut from './EditShortcut/EditShortcut.vue';
  import FileShortcut from './FileShortcut/FileShortcut.vue';
  import FormatShortcut from './FormatShortcut/FormatShortcut.vue';
  import HelpShortcut from './HelpShortcut/HelpShortcut.vue';
  export default {
    components: { ActionShortcut, EditShortcut, FileShortcut, FormatShortcut, HelpShortcut },
    props: { transcriptionName: { type: String } },
    data () {
      return { };
    },
    methods: {
      newTranscription () {
        this.$emit('newTranscription');
      },
      openTranscription () {
      },
      importTranscription () {
      },
      onCloseEditor () {
        this.$emit('close');
      },
      onSave () {
      },
      onExport (value) {
        this.$emit('export', value);
      },
      print () {
      },
      undo () {
      },
      redo () {
      },
      copy () {
      },
      cut () {
      },
      paste () {
      },
      expotTo () {
      },
      search () {
      },
      replace () {
      },
      insertLocationCursor () {
      },
      insertTextCursor () {
      },
      showPartNumberCheckBox (checked) {
      },
      showPartPagesCheckBox (checked) {
      },
      properties () {
      },
      preferences () {
      },
      onForwardClick () {
      },
      onBackwardClick () {
      },
      bold () {
      },
      italic () {
      },
      underline () {
      },
      fontFamily () {
      },
      fontColor () {
      },
      uppercase () {
      },
      lowercase () {
      },
      startLowercase () {
      },
      toggleBox () {
      },
      startUppercase () {
      },
      invertBox () {
      },
      about () {
      },
      tutorial () {
      }
    }
  };
</script>
<style lang="scss" scoped>
  .container-name {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: space-between;
    height: 40px;
    background: #F5F8FA;
    border-radius: 4px;
    }
  .btn-close {
    border: none;
    background: none;
    margin-right: 20px;
    &:focus {
      box-shadow: none;
    }

    & > img {
      width: 15px;
      height: 15px;
    }
  }
  .transcription-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 550;
    font-size: 18px;
    margin-top: 10px;
    margin-left: 20px;
    line-height: 17px;
    color: rgba(98, 120, 163, 1);

    }
  .line {
    &::after {
      content: "";
      position: absolute;
      height: 30px;
      margin-top: 3px;
      margin-left: 10px;
      width: 1.5px;
      background-color: #bdbdbd;
    }
  }
  .row {
    list-style: none;
    margin-left: 5px;
    height: 40px;
  }
</style>
