<template>
  <div>
    <b-sidebar id="sidebar" right shadow v-model="showVideo" class="sidebar" no-slide>
        <video ref="player" @timeupdate="onTimeUpdate" @play="onPlayVideo" @pause="onPauseVideo" preload="auto" class="videoPlayer"></video>
        <b-button class="new-user-button" @click="onClickPIP">PIP</b-button>
    </b-sidebar>
    <b-card class="wave-container">
      <av-waveform v-if="loaded" ref="wave" ref-link="player" class="audio-class" playedLineColor="dusty" :key="canvasWidth"
        cors-anonym :canv-width="canvasWidth" @contentType="contentTypeResponse($event)">
      </av-waveform>
    </b-card>
  </div>
</template>

<script>

  import AvWaveform from './components/AvWaveform';
  export default {
    components: {
      AvWaveform
    },
    data () {
      return {
        audioindex: 1,
        loaded: false,
        audioSrc: null,
        customInterval: 0,
        showVideo: false,
        canvasWidth: 1000,
        pipWindow: false
      };
    },
    props: { mediaPath: { type: String } },
    methods: {
      onShowVideo () {
        this.showVideo = !this.showVideo;
        if (this.showVideo) {
          this.canvasWidth = 600;
          this.$refs.wave.waveform();
        } else {
          this.canvasWidth = 1000;
          this.$refs.wave.waveform();
        }
      },
      updateVolume (value) {
        this.$refs.player.volume = value.volume;
      },
      loadAudio () {
        const audioCtx = new AudioContext();
        fetch(this.mediaPath)
          .then(resp => resp.arrayBuffer())
          .then(buf => audioCtx.decodeAudioData(buf))
          .then(audioBuffer => {
            const source = audioCtx.createBufferSource();
            source.buffer = audioBuffer;
            // source.playbackRate.value = 0.1;
            // source.loop = true;
            // source.start(0);
            const streamNode = audioCtx.createMediaStreamDestination();
            source.connect(streamNode);
            // const audioElem = new Audio();
            // audioElem.controls = true;
            // document.body.appendChild(audioElem);
            this.audioSrc = streamNode.stream;
            this.loaded = true;
          })
          .catch(console.error);
      },
      contentTypeResponse (contentType) {
        if (String(contentType).includes('mp3')) {
          this.$emit('showVideoButton', false);
        } else {
          this.$emit('showVideoButton', true);
        }
      },
      onTimeUpdate () {
        // const currentTime = parseFloat(
        //   this.$refs.player.currentTime.toFixed(2)
        // );
      },
      onClickPIP () {
        this.$refs.player.addEventListener('enterpictureinpicture', (event) => {
          console.log('> Video entered Picture-in-Picture');
          this.pipWindow = true;
        });

        this.$refs.player.addEventListener('leavepictureinpicture', (event) => {
          console.log('> Video leaved Picture-in-Picture');
          this.pipWindow = false;
        });

        this.$refs.player.requestPictureInPicture();
        this.$refs.player.controls = false;
        this.showVideo = false;
        this.$emit('pipVideo', !this.showVideo);
      },
      onPlayVideo () {
        if (this.pipWindow) {
          this.onPlay();
        } else {
        }
      },
      onPauseVideo () {
        if (this.pipWindow) {
          this.onPause();
        } else {
        }
      },
      onPlay () {
        clearInterval(this.customInterval);
        this.customInterval = setInterval(() => {
          if (this.$refs.player) {
            this.$emit('timeupdate', this.$refs.player.currentTime);
          }
        }, 42);
        this.$refs.player.play();
      },
      onPause () {
        clearInterval(this.customInterval);
        this.$refs.player.pause();
      },
      onStop () {
        clearInterval(this.customInterval);
        this.$refs.player.pause();
        this.$refs.player.currentTime = 0;
      },
      onForward () {
        console.log('onfor');
        this.$refs.player.currentTime += 5;
      },
      onBackward () {
        this.$refs.player.currentTime += -5;
      },
      setPosition (pos) {
        this.$refs.player.currentTime = pos;
      },
      closePIP () {
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture();
        }
      }
    },
    watch: {
      av () {
        window.location.reload();
      }
    },
    created () {

    },
    async mounted () {
      this.$refs.player.src = await this.mediaPath;
      this.$refs.player.load();
      this.$refs.player.addEventListener('enterpictureinpicture', (e) => {
        console.log('enterpictureinpicture: ', e);
      });
      this.$refs.player.addEventListener('loadeddata', (e) => {
        console.log('loadeddata');
      });
      this.loaded = true;
    }
  };
</script>

<style lang="scss" scoped>
.sidebar {
  align-content: stretch;
  display: flex;
  position: absolute;
  height: 30%;
}

.audio-class {
  background-color: #191d71;
}

.wave-container {
  width: 100%;
}

.videoPlayer {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: 30%;
    max-height: 30%;
    z-index: -100;
    background-size: cover;
    overflow: hidden;
}

.new-user-button {
    margin-left: 2%;
    background: #207BDD;
    border-radius: 4px;
    border-color: #207BDD;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    margin-top: 165%;
}

</style>
