<template>
<div>
    <div class="file-container">
       <div class="file-container">
         <b-nav-item-dropdown id="dropdown-main" offset="25" text="Ação" no-caret>
                <b-dropdown-item href="#" @click="onBackwardClick()"><p class="dropdown-item">Mover cursor para interlocutor anterior<span style="margin-left: 50px;">Ctrl + seta para cima</span></p></b-dropdown-item>
                <b-dropdown-item href="#" @click="onForwardClick()"><p class="dropdown-item">Mover para próximo interlocutor<span style="margin-left: 96px;">Ctrl + seta para baixo</span></p></b-dropdown-item>
                <b-dropdown-item href="#" @click="moveCursorToPlayback()"><p class="dropdown-item">Mover cursor para posição de reprodução<span style="margin-left: 25px;">Ctrl + Alt + barra de espaços</span></p></b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item href="#" @click="playPreviousWord()"><p class="dropdown-item">Reproduzir palavra anterior<span style="margin-left: 130px;">Ctrl + seta para esquerda</span></p></b-dropdown-item>
                <b-dropdown-item href="#" @click="playCurrentWord()"><p class="dropdown-item">Reproduzir palavra atual<span style="margin-left: 149px;">Ctrl + seta para baixo</span></p></b-dropdown-item>
                <b-dropdown-item href="#" @click="playNextWord()"><p class="dropdown-item">Reproduzir próxima palavra<span style="margin-left: 128px;">Ctrl + seta para direita</span></p></b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-nav-item-dropdown id="dropdown-secondary" text="Canais >>" class="ml-2 m-2 canais dropdown-item2" block dropright no-caret>
                    <b-dropdown-item ><p class="dropdown-item">Canal: Locutor 1</p></b-dropdown-item>
                    <b-dropdown-item ><p class="dropdown-item">Canal: Locutor 2</p></b-dropdown-item>
                    <b-dropdown-item ><p class="dropdown-item">Canal: Locutor 3</p></b-dropdown-item>
                    <b-dropdown-item ><p class="dropdown-item">Canal: Locutor 4</p></b-dropdown-item>
                    <b-dropdown-item ><p class="dropdown-item mb-2">Todos os canais</p></b-dropdown-item>
                </b-nav-item-dropdown>
            </b-nav-item-dropdown>
       </div>
   </div>
</div>
</template>

<script>
  export default {
    data () {
      return {
        isDropdownSecondaryVisible: false
      };
    },
    mounted: function () {
      this.$root.$on('bv::dropdown::show', bvEvent => {
        if (bvEvent.componentId === 'dropdown-secondary') {
          this.isDropdownSecondaryVisible = true;
        }
      });
      this.$root.$on('bv::dropdown::hide', bvEvent => {
        if (bvEvent.componentId === 'dropdown-secondary') {
          this.isDropdownSecondaryVisible = false;
        }
        if (this.isDropdownSecondaryVisible) {
          bvEvent.preventDefault();
        }
      });
    },
    methods: {
      onForwardClick () {
      },
      onBackwardClick () {
      },
      moveCursorToPlayback () {
      },
      playPreviousWord () {
      },
      playCurrentWord () {
      },
      playNextWord () {
      }
    }
  };
</script>

<style lang="scss" scoped>
.file-container h6 {
    color: #153252;
    font-family: Poppins;
    font-weight: 600;
    margin-left: 23px;
    margin-top: 5px;
}
.dropdown-item {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: #6278A3;
  height: 18px;
  padding: 0 0 0 0 !important;
  margin-top: 0;
  margin-bottom: 0;
}
.dropdown-item2 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: #6278A3;
  height: 18px;
  padding: 0 0 0 0 !important;
  margin-top: 0;
  margin-bottom: 0;
}
.dropdown-item2:hover {
  background-color: #F5F8FA;
}
.channels {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: #6278A3;
    padding-bottom: 10px;
}
.card-body {
    padding-top: 0;
    padding-bottom: 10px;
}
.canais {
    height: 35px;
}

</style>
