<template>
    <div>
        <div class="help-container">
    <div class="file-container">
    <b-nav-item-dropdown offset="25" text="Ajuda" class="nav-dropdown" no-caret>
        <b-dropdown-item href="#" @click="about()"><p class="dropdown-item">Sobre</p></b-dropdown-item>
        <b-dropdown-item href="#" @click="tutorial()"><p class="dropdown-item mb-2">Tutorial básico</p></b-dropdown-item>
    </b-nav-item-dropdown>
    </div>
   </div>
    </div>
</template>

<script>
  export default {
    data () {
      return {
      };
    },
    methods: {
      about () {
      },
      tutorial () {
      }
    }
  };
</script>

<style lang="scss" scoped>
.file-container h6 {
    color: #153252;
    font-family: Poppins;
    font-weight: 600;
    margin-left: 23px;
}
.dropdown-item {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: #6278A3;
    height: 18px;
    padding: 0 0 0 0 !important;
    margin-top: 0;
    margin-bottom: 0;
}
</style>
