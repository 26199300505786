import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';

class DocumentCreator {
  async createDocx (content) {
    const paragraph = [];
    for (const child of content.children) {
      const children = [];
      children.push(new TextRun(child.innerText));
      paragraph.push(new Paragraph({ children }));
    }
    const doc = new Document({
      sections: [{
        properties: {},
        children: paragraph
      }]
    });
    return doc;
  }

  async generateDocx (doc, fileName) {
    Packer.toBlob(doc).then(blob => {
      saveAs(blob, fileName);
    });
  }
}

export default DocumentCreator;
