<template>
    <div>
      <div class="player-controls">
        <div class="video-controls">
          <b-button @click="onClickRating()" squared id="rating" style="background: none; border: none;" class="btn-video-controls">
            <img src="@/assets/icons/action/star_button.svg" height="20px" width="20px" />
          </b-button>
          <b-button squared style="background: none; border: none;" class="btn-video-controls" @click="showModalQuality()">
            <img src="@/assets/icons/action/stamp_button.svg" height="20px" width="20px" />
          </b-button>
          <Rating ref="modalRating" :transcriptionId="transcriptionId" @rating="rating()" />
          <Chart ref="modalChart" :quality="quality"></Chart>
        </div>
      </div>
    </div>
</template>
<script>
  import Rating from './Rating';
  import Chart from './Chart';
  export default {
    components: { Rating, Chart },
    props: { transcriptionId: { type: String }, quality: { type: Object } },
    data () {
      return {
        transcription: {
          id: null
        }
      };
    },
    methods: {
      onClickRating () {
        this.$refs.modalRating.showModal();
      },
      showModalQuality () {
        this.$refs.modalChart.showModal();
      }
    }
  };
</script>

<style lang="scss" scoped>

  .player-controls {
    margin-left: 35px;
    margin-top: 0px;

    .video-controls {
      .btn-video-controls {
        padding: 0;
        border: none;
        background: none;
        margin-right: 15px;
        margin-top: 3px;
        margin-bottom: 3px;

        &:focus {
          box-shadow: none;
        }

        & > img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
  .player-controls {
    margin-top: 3px;
  }
</style>
