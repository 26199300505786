<template>
    <div>
        <b-modal
          id="modal-prevent-closing"
          ref="modal"
          cancel-title="Cancelar"
          cancel-variant="transparent"
          @hidden="resetModal"
          title="Selecionar Fontes">
            <b-form inline>
                  <p class="fontes">Fontes disponíveis</p>
                  <p class="fonteSize">Tamanho </p>
                  <b-form-select v-model="selected" style="margin-top: -15px; font-size:17px; height: 30px; text-align: center; margin-left: 16px; width: 82px !important; border-radius: 2px;" :options="options"></b-form-select>
                <b-input-group size="sm" class="mb-0">
                <b-input-group-prepend is-text>
                      <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                  <b-form-input id="inline-form-input-name" class="mb-2 mr-sm-2 mb-sm-0 input-search"
                  placeholder="Buscar..." v-model="search"></b-form-input>
                </b-input-group>
            </b-form>
            <b-list-group class="list-group" style="margin-top: 10px">
                  <b-list-group-item button v-for="(item, key) in filteredItems" :key="key" @click="onClickFontItem(item)">
                    {{ item.name }}
                  </b-list-group-item>
            </b-list-group>
                <b-form-textarea style="border-radius: 1px; border: 1px; box-shadow: 0 0 10px #719ECE"  class="textarea" ref="fontTextArea" id="textarea-no-resize" rows="3" no-resize v-model="selectedFontName"></b-form-textarea>
                <template v-slot:modal-footer>
            </template>
            </b-modal>
      </div>
</template>

<script>
  export default {
    data () {
      return {
        selectedFontName: '',
        title: 'Selecionar',
        fields: [
        ],
        items: [
          {
            name: 'Arial Black'
          },
          {
            name: 'Bahnschrift'
          },
          {
            name: 'Calibri'
          },
          {
            name: 'Cambria'
          },
          {
            name: 'Cambria Math'
          },
          {
            name: 'Candara'
          },
          {
            name: 'Comic Sans MS'
          },
          {
            name: 'Consolas'
          },
          {
            name: 'Constantia'
          },
          {
            name: 'Corbel'
          },
          {
            name: 'Courier New'
          },
          {
            name: 'Ebrima'
          }
        ],
        search: '',
        selected: null,
        options: [
          { value: null, text: '8' },
          { value: 9, text: '9' },
          { value: 10, text: '10' },
          { value: 11, text: '11' },
          { value: 12, text: '12' },
          { value: 14, text: '14' },
          { value: 18, text: '18' },
          { value: 24, text: '24' },
          { value: 30, text: '30' },
          { value: 36, text: '36' },
          { value: 48, text: '48' },
          { value: 60, text: '60' },
          { value: 72, text: '72' }
        ]
      };
    },
    computed: {
      filteredItems () {
        let items = [];
        items = this.items.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
        items = items.filter((item) => {
          if (this.selected == null) return item;
          return item.isActive === this.selected;
        });
        return items;
      }
    },
    methods: {
      fontSelected () {
        this.$emit('fontFamily', this.selectedFontFamily);
      },
      showModal () {
        this.$refs.modal.show();
      },
      resetModal () {
        this.emailState = null;
        this.editing = false;
      },
      handleModalOk (bvModalEvt) {
        bvModalEvt.preventDefault();
        this.fontSelected();
        this.$nextTick(() => {
          this.$refs.modal.hide();
        });
      },
      async hideModal () {
        this.$refs.modal.hide();
      },
      onClickFontItem (item) {
        this.selectedFontName = item.name;
        this.$refs.fontTextArea.$el.style.fontFamily = this.selectedFontName;
      }
    }
  };
</script>

<style lang="scss" scoped>
 .search-field {
    padding-bottom: 15px;
  }
  .fontes{
    display: flex;
    color: #6278A3;
    font-family: Poppins;
    }

  .fonteSize {
    margin-left: 112px;
    padding-right: 10px;
    color: #6278A3;
    font-family: Poppins;
  }
  .textarea {
    width: 100%;
    border: none;
    text-align: center;
    margin-top: 20px;
  }
  #inline-form-input-name {
    width: 410px;
    font-family: Poppins;
    color: #6278A3;
  }
  .search {
    width: 98%;
  }
  .list-group-item {
    color: #153252;
    margin-top: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-top: none;
    border-left: none;
    border-right: none;
    line-height: 15px;
    font-size: 16px;
    margin-left: -5px;
    font-family: Poppins;
  }
  .modal-title {
    line-height: 20px;
  }
  .list-group{
      max-height: 250px;
      margin-bottom: 10px;
      overflow:scroll;
      -webkit-overflow-scrolling: touch;
  }
  .custom-select {
    color: #6278A3;
    line-height: 8px;
    width: 10px;
  }

  </style>
