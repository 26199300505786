<template>
  <div>
    <b-modal
      ref="modal"
      :ok-title="submitTitle"
      @hidden="resetModal"
      @ok="handleModalOk"
      title="Qualidade">
      <div>
        <h4 class="pb-3" style="font-weight:500; font-size: 1.15rem !important; font-family: Poppins !important;">Qualidade da transcrição: {{transcriptionQuality}}</h4>
        <p class="mt-2 quality-rating" style="font-weight:400; font-size: 14px !important; font-family: Inter !important;">A transcrição deste arquivo foi avaliada como sendo de boa qualidade. Poucos ajustes podem ser necessários para tornar o material completo.
          Você pode solicitar a contratação de um corretor Retextoar para não necessitar realizar ajustes manuais.</p>
      </div>
      <hr>
      <div>
        <Bar
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </div>
      <template v-slot:modal-footer>
      <b-button class="btn btn-primary" @click="hideModalRating()">Fechar</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
  import { transcriptionService } from '../../../services/transcription.service';
  import { Bar } from 'vue-chartjs/legacy';

  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
  } from 'chart.js';

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

  export default {
    components: { Bar },
    props: {
      quality: {
        type: Object
      },
      chartId: {
        type: String,
        default: 'bar-chart'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 100
      },
      height: {
        type: Number,
        default: 120
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => {}
      },
      plugins: {
        type: Object,
        default: () => {}
      }
    },
    data () {
      return {
        submitTitle: 'Continuar',
        transcriptionQuality: this.qualityStatusPt(this.quality.status),
        chartData: {
          labels: ['Palavras'],
          datasets: [
            {
              label: 'Alta',
              data: [this.quality.count.good],
              backgroundColor: '#428e11',
              hoverBackgroundColor: '#83dc28',
              hoverBorderWidth: 0,
              order: 1
            },
            {
              label: 'Média',
              data: [this.quality.count.medium],
              backgroundColor: '#FFA000',
              hoverBackgroundColor: '#FFCA28',
              hoverBorderWidth: 0,
              order: 2
            },
            {
              label: 'Baixa',
              data: [this.quality.count.poor],
              backgroundColor: '#D32F2F',
              hoverBackgroundColor: '#EF5350',
              hoverBorderWidth: 0,
              order: 3
            }
          ],
          options: {
            responsive: true,
            scales: {
              xAxes: [{
                stacked: true
              }],
              yAxes: [{
                stacked: true
              }]
            }
          }
        },
        chartOptions: {
          responsive: true,
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true
            }
          }
        }
      };
    },
    methods: {
      checkFormValidity () {
        this.commentsState = this.comments.length > 4;
        this.ratingState = this.ratingValue > 0;
        return this.commentsState && this.ratingState;
      },
      qualityStatusPt (statusEn) {
        if (statusEn === 'good') {
          return 'Boa';
        } else if (statusEn === 'medium') {
          return 'Média';
        } else if (statusEn === 'poor') {
          return 'Fraca';
        } else {
          return 'Inconclusiva';
        }
      },
      showModal () {
        this.$refs.modal.show();
      },
      resetModal () {
        this.emailState = null;
        this.editing = false;
      },
      async handleModalOk (bvModalEvt) {
        bvModalEvt.preventDefault();
        if (!this.checkFormValidity()) {
          return;
        }
        await transcriptionService.rating(this.transcriptionId, { comment: this.comments, score: this.ratingValue });
        this.$toast.open('Avaliação registrada com sucesso!');
        this.$nextTick(() => {
          this.$refs.modal.hide();
        });
      },
      async hideModalRating () {
        this.$refs.modal.hide();
      }
    }
  };
</script>
<style lang="scss" scoped>

  /deep/.modal-header h5 {
    color: #004A78;
    font-weight: 600;
    border: none !important;
  }
  .btn-primary {
    background-color: #207BDD;
    border: none;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-family: Poppins;
    margin-left: 330px;
  }

  .modal-footer .btn-transparent {
    color: #007bff;
    font-weight: 500;
    background-color: #EBEFF2;
    border: none;
  }
  .textarea-text {
    width: 100%;
    height: 93px;
    color: #6278A3 !important;
    font-weight: 400 !important;
    margin-top: 10px !important;
    padding-top: 20px;
  }
  .form-file-text {
    color: #6278A3 !important;
  }
  .quality-rating {
    width: 100%;
    color: #6278A3;
    font-size: 16px;
    font-weight: 400;
    font-family: Inter;
  }

  .b-rating.d-inline-flex {
    width: 100%;
    padding-left: 150px;
    padding-right: 150px;
    height: 50px;
    color: #6278A3;
    font-size: 20px;
  }
  .quality {
    color: #207BDD;
    width: 100%;
    font-size: 1.25rem;
  }
  #inline-form-input-name {
    border-radius: 2px;
  }

</style>
