<template>
    <div>
          <b-modal
            ref="modal"
            :ok-title="submitTitle"
            cancel-title="Cancelar"
            @hidden="resetModal"
            @ok="handleModalOk"
            title="Avaliação da transcrição">
            <div>
              <p class="mt-2 quality-rating" style="font-weight:500">Qualidade da transcrição</p>
              <b-form-group :state="ratingState" invalid-feedback="Avaliação necessária">
                <b-form-rating v-model="ratingValue" inline class="rating" ></b-form-rating>
              </b-form-group>
            </div>
            <hr>
            <b-form-textarea class="textarea-text" v-model="comments" rows="3" no-resize placeholder="Escreva um comentário sobre a qualidade da transcrição." :state="commentsState" invalid-feedback="Descrição é necessária"></b-form-textarea>
            <template v-slot:modal-footer>
              <b-button class="btn btn-transparent" @click="hideModalRating()">
              Cancelar
              </b-button>
              <b-button class="btn btn-primary" @click="handleModalOk($event)">
              Enviar
              </b-button>
              </template>
            </b-modal>
        </div>
</template>
<script>
  import { transcriptionService } from '../../../services/transcription.service';

  export default {
    props: { transcriptionId: { type: String } },
    data () {
      return {
        submitTitle: 'Enviar',
        comments: '',
        ratingValue: 0,
        ratingState: null,
        commentsState: null
      };
    },
    methods: {
      checkFormValidity () {
        this.commentsState = this.comments.length > 4;
        this.ratingState = this.ratingValue > 0;
        return this.commentsState && this.ratingState;
      },
      showModal () {
        this.$refs.modal.show();
      },
      resetModal () {
        this.emailState = null;
        this.editing = false;
      },
      async handleModalOk (bvModalEvt) {
        bvModalEvt.preventDefault();
        if (!this.checkFormValidity()) {
          return;
        }
        await transcriptionService.rating(this.transcriptionId, { comment: this.comments, score: this.ratingValue });
        this.$toast.open('Avaliação registrada com sucesso!');
        this.$nextTick(() => {
          this.$refs.modal.hide();
        });
      },
      async hideModalRating () {
        this.$refs.modal.hide();
      }
    }
  };
  </script>
  <style lang="scss" scoped>

/deep/.modal-header h5 {
    color: #004A78;
    font-weight: 600;
    border: none !important;
  }
.modal-footer .btn-primary {
    background-color: #207BDD;
    border: none;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-family: Poppins;
  }

  .modal-footer .btn-transparent {
    color: #007bff;
    font-weight: 500;
    background-color: #EBEFF2;
    border: none;
  }
.textarea-text {
  width: 100%;
  height: 93px;
  color: #6278A3 !important;
  font-weight: 400 !important;
  margin-top: 10px !important;
  padding-top: 20px;
}
.form-file-text {
  color: #6278A3 !important;
}
.quality-rating {
  width: 100%;
  color: #6278A3;
  font-size: 1.25rem;
  font-weight: 700 !important;
  font-family: Poppins;
}

.b-rating.d-inline-flex {
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  height: 50px;
  color: #6278A3;
  font-size: 20px;
}
.quality {
  color: #207BDD;
  width: 100%;
  font-size: 1.25rem;
}
#inline-form-input-name {
  border-radius: 2px;
}

</style>
