<template>
    <div>
        <div class="format-container">
    <div class="file-container">
    <b-nav-item-dropdown offset="25" text="Formatar" style="color: red" class="nav-dropdown" no-caret>
        <b-dropdown-item href="#" @click="bold()"><p class="dropdown-item"><img src="@/assets/icons/format/bold_shortcut.svg" style="margin-right: 17px"/>Negrito<span style="margin-left: 267px;">Ctrl + B</span></p></b-dropdown-item>
        <b-dropdown-item href="#" @click="italic()"><p class="dropdown-item"><img src="@/assets/icons/format/italic_shortcut.svg" style="margin-right: 12px; width:15px; height: 15px"/>Itálico<span style="margin-left: 275px;">Ctrl + I</span></p></b-dropdown-item>
        <b-dropdown-item href="#" @click="underline()"><p class="dropdown-item"><img src="@/assets/icons/format/underline.svg" style="margin-right: 15px; width:12px"/>Sublinhado<span style="margin-left: 238px;">Ctrl + U</span></p></b-dropdown-item>
        <b-dropdown-item href="#" @click="fontFamily()"><p class="dropdown-item"><img src="@/assets/icons/format/font_family_shortcut.svg" style="margin-right: 15px"/>Fonte<span style="margin-left: 279px;">Ctrl + Alt + F</span></p></b-dropdown-item>
        <b-dropdown-item href="#" @click="fontColor()"><p class="dropdown-item"><img src="@/assets/icons/format/font_color.svg" style="margin-right: 15px"/>Cor da fonte</p></b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item href="#" @click="upercase()"><p class="dropdown-item">MAIÚSCULAS</p></b-dropdown-item>
        <b-dropdown-item href="#" @click="lowercase()"><p class="dropdown-item">minúculas</p></b-dropdown-item>
        <b-dropdown-item href="#" @click="startLowercase()"><p class="dropdown-item">Palavras Iniciando Com Maiúsculas</p></b-dropdown-item>
        <b-dropdown-item href="#" @click="toggleBox()"><p class="dropdown-item">Alternar caixa<span style="margin-left: 248px;">Shift + F3</span></p></b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item href="#" @click="startUppercase()"><p class="dropdown-item">Frase iniciando com maiúscula</p></b-dropdown-item>
        <b-dropdown-item href="#" @click="invertBox()"><p class="dropdown-item mb-2">iNVERTER cAIXA</p></b-dropdown-item>
    </b-nav-item-dropdown>
    </div>
   </div>
    </div>
</template>

<script>
  export default {
    data () {
      return {
      };
    },
    methods: {
      bold () {
      },
      italic () {
      },
      underline () {
      },
      fontFamily () {
      },
      fontColor () {
      },
      upercase () {
      },
      lowercase () {
      },
      startLowercase () {
      },
      toggleBox () {
      },
      startUppercase () {
      },
      invertBox () {
      }
    }
  };
    </script>

<style lang="scss" scoped>
.file-container h6 {
    color: #153252;
    font-family: Poppins;
    font-weight: 600;
    margin-left: 23px;
    margin-top: 5px;
}
.dropdown-item {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: #6278A3;
    height: 18px;
    padding: 0 0 0 0 !important;
    margin-top: 0;
    margin-bottom: 0;
}
</style>
