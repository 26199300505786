<template>
    <div id="file-container">
          <b-modal ref="modal" @ok="handleModalOk"
            title="Exportar como:"
            target="file-export"
            triggers="click"
            placement="bottom"
            hide-footer>
            <div class="body-export">
                <b-button squared id="file-export" @click="exportTranscription()" style="background: none; border: none; margin-left: 20px;">
                    <b-link @click="onWordExport()" squared variant="primary" style="background: none; border: none; margin-left: 60px" >
                        <img src="@/assets/icons/word.svg" height="80px" width="80px" />
                    </b-link>
                    <b-link @click="onPdfExport()" squared variant="primary" style="background: none; border: none; margin-left: 80px">
                        <img src="@/assets/icons/pdf.svg" height="95px" width="95px" />
                    </b-link>
                </b-button>
            </div>
            </b-modal>
        </div>
</template>
<script>
  export default {
    data () {
      return { };
    },
    methods: {
      showModal () {
        this.$refs.modal.show();
      },
      async handleModalOk (bvModalEvt) {
        bvModalEvt.preventDefault();
      },
      onPdfExport () {
        this.$emit('export', 'pdf');
        this.$refs.modal.hide();
      },
      onWordExport () {
        this.$emit('export', 'word');
        this.$refs.modal.hide();
      },
      hideModalExport () {
        this.$refs.modal.hide();
      },
      exportTranscription () {
      }
    }
  };
  </script>
  <style lang="scss" scoped>

/deep/.modal-header h5 {
    color: #004A78;
    font-weight: 600;
    border: none !important;
  }
.modal-footer .btn-primary {
    background-color: #207BDD;
    border: none;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-family: Poppins;
  }

  .modal-footer .btn-transparent {
    color: #007bff;
    font-weight: 500;
    background-color: #EBEFF2;
    border: none;
  }

.form-file-text {
  color: #6278A3 !important;
}

#inline-form-input-name {
  border-radius: 2px;
}

.body-export {
    height: 120px;
    justify-content: space-between;
}

.modal-body {
    padding: 10px;
}

.endOfContent{
  background-color: yellow !important;
}

</style>
